import Alpine from "alpinejs";
import mask from "@alpinejs/mask";
import anchor from "@alpinejs/anchor";
//import collapse from "@alpinejs/collapse";
import Tooltip from "@ryangjchandler/alpine-tooltip";
import Clipboard from "@ryangjchandler/alpine-clipboard";
import AlpineLazyLoadAssets from "alpine-lazy-load-assets"; // ? may not need this
//import Autosize from "@marcreichel/alpine-autosize";
import autoAnimate, { getTransitionSizes } from "@formkit/auto-animate";
//import persist from '@alpinejs/persist'
//import autoAnimate from "@formkit/auto-animate";
Alpine.plugin(AlpineLazyLoadAssets);
Alpine.plugin(mask);
Alpine.plugin(Tooltip);
Alpine.plugin(Clipboard);
//Alpine.plugin(Autosize);
//Alpine.plugin(persist);
Alpine.plugin(anchor);
// Don't assign Alpine to the window (keep it private):
window.Alpine = Alpine;
Alpine.directive("animate", (parentEl) => {
  //autoAnimate(el);
  /*
// autoAnimate(el, {
//   // Animation duration in milliseconds (default: 250)
//   duration: 250,
//   // Easing for motion (default: 'ease-in-out')
//   easing: 'ease-in-out',
//   // When true, this will enable animations even if the user has indicated
//   // they don’t want them via prefers-reduced-motion.
//   disrespectUserMotionPreference: false
// })
// */
// 
autoAnimate(parentEl, (el, action, oldCoords, newCoords) => {
  let keyframes;
  // supply a different set of keyframes for each action
  if (action === "add") {
    keyframes = [
      { transform: "scale(1)", opacity: 0 },
      //{ transform: "scale(1)", opacity: 1, offset: 0.75 },
      { transform: "scale(1)", opacity: 1 },
    ];
  }
  // keyframes can have as many "steps" as you prefer
  // and you can use the 'offset' key to tune the timing
  if (action === "remove") {
    keyframes = [
      { transform: "scale(1)", opacity: 1 },
      //{ transform: "scale(1)", opacity: 1, offset: 0.75 },
      { transform: "scale(1)", opacity: 0 },
    ];
  }
  if (action === "remain") {
    // for items that remain, calculate the delta
    // from their old position to their new position
    const deltaX = oldCoords.left - newCoords.left;
    const deltaY = oldCoords.top - newCoords.top;
    // use the getTransitionSizes() helper function to
    // get the old and new widths of the elements
    const [widthFrom, widthTo, heightFrom, heightTo] = getTransitionSizes(el, oldCoords, newCoords);
    // set up our steps with our positioning keyframes
    const start = { transform: `translate(${deltaX}px, ${deltaY}px)` };
    const mid = { transform: `translate(${deltaX * -0.15}px, ${deltaY * -0.15}px)`, offset: 0.75 };
    const end = { transform: `translate(0, 0)` };
    // if the dimensions changed, animate them too.
    if (widthFrom !== widthTo) {
      start.width = `${widthFrom}px`;
      mid.width = `${widthFrom >= widthTo ? widthTo / 1.05 : widthTo * 1.05}px`;
      end.width = `${widthTo}px`;
    }
    if (heightFrom !== heightTo) {
      start.height = `${heightFrom}px`;
      mid.height = `${heightFrom >= heightTo ? heightTo / 1.05 : heightTo * 1.05}px`;
      end.height = `${heightTo}px`;
    }
    keyframes = [start, mid, end];
  }
  // return our KeyframeEffect() and pass
  // it the chosen keyframes.
  return new KeyframeEffect(el, keyframes, { duration: 200, easing: "ease-in" });
});
});
// Assign a custom prefix:
Alpine.prefix("yd-"); // for Yabdab ( use on multiple projects )
Alpine.start();
